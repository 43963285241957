import React, {useState} from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import {useTranslation} from "react-i18next";
import MainMenu from "../business/service/MainMenu";




export default function GuestLayout() {
    const {t, i18n} = useTranslation();
    const { user } = useAuth();
    const [toggled, setToggled] = useState(false);
    const [containerPadding, setContainerPadding] = useState(205);

    let lng = i18n.language;
    if (lng==='cn') {lng='zh-Hans'}
    if (lng==='hk') {lng='zh-Hant'}
    if (lng==='my') {lng='ms'}
    if (lng==='us') {lng='en'}
    if (lng==='au') {lng='en'}
    document.documentElement.lang = lng;


    function changeToggled(){

        if (toggled){
            setToggled(false);
            setContainerPadding(205);
        }else {
            setToggled(true);
            setContainerPadding(0);
        }
    }

    // if user is logged in, redirect to profile page
    return (
        <>
            <MainMenu type={'business'} company={''} changeToggled={changeToggled} mode={'guest'}/>


            <Outlet/>
        </>
    );
}
