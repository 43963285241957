
import React, {useEffect, useState} from "react";
import Modal from "./Modal.jsx";
import {useTranslation} from "react-i18next";
import axios from '../../axios.jsx';
import {Navigate, useNavigate, useParams} from "react-router-dom";



const languagesConversation = [
    {id: 2, language_code: "en", language_name: "English (UK)",  language_order:1},
    {id: 3, language_code: "us", language_name: "English (US)",  language_order:2},
    {id: 4, language_code: "au", language_name: "English (AU)",  language_order:3},
    {id: 5, language_code: "id", language_name: "Bahasa Indonesia", language_order:4},
    {id: 13, language_code: "my", language_name: "Bahasa Malaysia",  language_order:5},
    {id: 1, language_code: "ru", language_name: "Русский",  language_order:6},
    {id: 16, language_code: "jp", language_name: "日本",  language_order:7},
    {id: 17, language_code: "hk", language_name: "繁體中文",  language_order:8},
    {id: 18, language_code: "cn", language_name: "简体中文",  language_order:9},
    {id: 15, language_code: "ko", language_name: "한국인",  language_order:10},
    {id: 14, language_code: "hi", language_name: "हिंदी",  language_order:11},
    {id: 22, language_code: "tl", language_name: "Filipino",  language_order:12},
    {id: 23, language_code: "vi", language_name: "Việt",  language_order:13},
    {id: 24, language_code: "th", language_name: "ภาษาไทย",  language_order:14},
];

const languagesClient = [
    {id: 2, language_code: "en", language_flag: "en", language_name: "Global English",  language_order:1},
    {id: 3, language_code: "en", language_flag: "us", language_name: "United States (English)",  language_order:2},
    {id: 4, language_code: "en", language_flag: "au", language_name: "Australia (English)",  language_order:3},
    {id: 9, language_code: "ru", language_flag: "ru", language_name: "Русский", language_order:8}
];

export default function LanguageSelector(props) {

    const {t, i18n} = useTranslation();
    //const [languages, setLanguages] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const navigate = useNavigate();

    const { params } = useParams();
    const [languages, setLanguages] = useState(languagesConversation);

    //console.log(props.mode);
    useEffect(() => {
       /* axios.get('languages').then((resp) => {
            const languages = resp.data;
            setLanguages(languages);
            console.log(languages);
        });*/
        (async () => {
            axios.get('currencies-for-clients/'+i18n.language).then((resp) => {
                const currencies = resp.data;
                setCurrencies(currencies);
               // console.log(currencies);
            });

        })();




    }, []);

    function clickLanguage(e){

        var currentLanguage= {
            id: e.currentTarget.getAttribute('language_id'),
            language_code: e.currentTarget.getAttribute('language_code'),
            language_name: e.currentTarget.getAttribute('language_name'),
            language_flag: e.currentTarget.getAttribute('language_flag'),
        }

        if (props.mode === 'multiple')
        {
            //var parent = this._reactInternalInstance._currentElement._owner._instance;
            //console.log(e.currentTarget.getAttribute('class-name'));
           // let languages = props.languages;

            if (e.currentTarget.getAttribute('active') === 'inactive') {
                e.currentTarget.setAttribute('active', 'active');
                e.currentTarget.style.backgroundColor = 'rgba(0,0,0,0.2)';
                props.setLanguages(props.languages.filter(lng => lng.id !== currentLanguage.id));
                props.setLanguages([...props.languages, currentLanguage]);
            } else {
                e.currentTarget.setAttribute('active', 'inactive');
                e.currentTarget.style.backgroundColor = 'transparent';
                props.setLanguages(props.languages.filter(lng => lng.id !== currentLanguage.id));

            }
        }

        if (props.mode === 'single') {
             props.setLanguage(currentLanguage);
             props.closeModal();
        }

        if (props.mode === 'change-language-business')
        {
            props.i18n.changeLanguage(currentLanguage.language_code);
            props.closeModal();
        }

        if (props.mode === 'change-language-client')
        {

            let link = '/'+currentLanguage.language_code+window.location.pathname.substring(3)+window.location.search;
            //console.log(props.type);

          // console.log(111);
            if (props.changeType==='main'){
                window.location.replace(link);
               // window.location.reload();
                //console.log(222);
            }
            else {
                i18n.changeLanguage(currentLanguage.language_code);
                localStorage.setItem('flag', currentLanguage.language_flag);
                window.location.reload();
            }
           // window.location.reload();

            //props.i18n.changeLanguage('us');
            //props.closeModal();
           // console.log(params);

           // window.location.replace(link);
        }

        //console.log(props.languages);

    }

    const renderLanguages = () => {
        //console.log('eee');
        let renderLanguage = languagesConversation;
        if (props.mode === 'change-language-client'){
            renderLanguage =  languagesClient;
        }

        return renderLanguage.map(language => {
            var isFind = 'inactive';
            //console.log(props.languages);
            //console.log(language.id);
            if (props.mode === 'multiple') {
                if ((language.language_code == 'us') || (language.language_code == 'au')){return '';}
                isFind = props.languages.find(lng => lng.id == language.id);
                if (isFind) {
                    isFind = 'active';
                } else {
                    isFind = 'inactive'
                }
            }

            if (props.mode === 'change-language-client') {
                if (language.language_flag === localStorage.getItem('flag')){isFind = 'active';}
            }
            //console.log(isFind);


            return (
                <div className={`language-element ${isFind}`}
                     key={language.id}
                     language_id={language.id}
                     language_code={language.language_code}
                     language_name={language.language_name}
                     language_flag={language.language_flag}
                     active={`${isFind}`}
                     onClick={clickLanguage}>
                    <div className={'language-flag'} ><img src={require('../../i/'+language.language_flag+'.png')} width={30} height={30}/>  </div>
                    <div className={'language-name'}>{ language.language_name }</div>
                </div>
            );
        })
    };

    function clickCurrency(currency){
        localStorage.setItem('currency_code', currency);

       // var url = "http://www.google.com/";
       // window.location = url;
        window.location.replace(window.location.href);


        //window.location.update();
    }


    const renderCurrencies = () => {
        return currencies.map(currency => {
            var isFind = 'inactive';
            //console.log(props.languages);
            //console.log(language.id);
            let style = 'normal';
            if (currency.currency_code==='usd') {style = 'bold';}


            return (
                <div className={`language-element ${isFind}`}
                     style={{fontWeight: style}}
                     key={currency.currency_code}
                     currency_code={currency.currency_code}
                     /*language_id={language.id}
                     currency_code={language.language_code}
                     language_name={language.language_name}*/
                     onClick={() => clickCurrency(currency.currency_code)}
                     active={`${isFind}`}
                     >
                    <div className={'currency-code'} >{currency.currency_code} - </div>
                    <div className={'language-name'}>{ currency.currency_name }</div>
                </div>
            );
        })
    };

    return (
        <>

            {(props.type === 'language') && <div className={"language-selector-container"}>
                { renderLanguages() }
            </div>}

            {(props.type === 'currency') && <div className={"language-selector-container"}>
                { renderCurrencies() }
            </div>
            }


        </>
    );
}

/*

<button onClick={() => i18n.changeLanguage('en')}>English</button>&nbsp;
    <button onClick={() => i18n.changeLanguage('ru')}> Русский </button>
 */
