import i18next from "i18next";

import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import "dayjs/locale/vi";
import "dayjs/locale/ru";
import "dayjs/locale/th";
import "dayjs/locale/en-au";
import "dayjs/locale/en-gb";
import "dayjs/locale/en";
import "dayjs/locale/ko";
import "dayjs/locale/zh-cn";
import "dayjs/locale/id";
import "dayjs/locale/tl-ph";
import "dayjs/locale/ms-my";
import "dayjs/locale/hi";
import "dayjs/locale/ja";
import "dayjs/locale/zh-hk";

import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';
import ruRU from 'antd/es/locale/ru_RU';
import koKO from 'antd/es/locale/ko_KR';
import tlPH from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';
import enGB from 'antd/es/locale/en_GB';
import enAU from 'antd/es/locale/en_GB';
import idID from 'antd/es/locale/id_ID';
import viVN from 'antd/es/locale/vi_VN';
import msMy from 'antd/es/locale/ms_MY';
import zhHK from 'antd/es/locale/zh_HK';
import jpJP from 'antd/es/locale/ja_JP';
import hiHI from 'antd/es/locale/hi_IN';

export const localesAntd  = {
    us: enUS,
    ru: ruRU,
    cn: zhCN,
    tl: tlPH,
    ko: koKO,
    en: enGB,
    au: enAU,
    vi: viVN,
    id: idID,
    th: thTH,
    jp: jpJP,
    hk: zhHK,
    my: msMy,
    hi: hiHI,
}



// "Inline" English and Arabic translations.

// We can localize to any language and any number of languages.


i18next

    .use(initReactI18next)
    .use(HttpApi)
    .use(LanguageDetector)

    .init({

        detection: {
            order: ['path', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'subdomain'],
            lookupFromPathIndex: 0,

        },


        fallbackLng: "us",
        supportedLngs: ["en", "ru", "id",  "au", "us", "ko", "tl", "vi", "cn", "th", "hk", "jp", "my", "hi"],

        interpolation: {

            escapeValue: false,

        },
        /*debug: process.env.NODE_ENV === "development",*/

    }

    );

export default i18next;
