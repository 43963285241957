import Axios from 'axios';

const axios = Axios.create({
    baseURL: process.env.REACT_APP_DOMAIN_NAME_API+"/api",
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        'X-Requested-With': 'XMLHttpRequest',

    },
});

export default axios;
