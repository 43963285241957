import { createContext, useContext, useState, useEffect } from 'react';
import axios from '../axios.jsx';
import {useAuth} from "./AuthContext.jsx";


const MessagesContent = createContext({
    messagesArray: null,
    setMessagesArray: () => {},
    UpdateMessages: () => {}
});

export const MessagesProvider = ({ children }) => {
    const [messagesArray, setMessagesArray] = useState(['eee','eee']);
    const {user} = useAuth();

    const UpdateMessages = () => {
        //console.log(1111);
        //console.log(user);

        if (user?.email) {
            //console.log(444);
            axios.get('/messages/check-business/'+localStorage.getItem('company_id')).then((resp) => {
                //console.log(resp.data);
                setMessagesArray(resp.data);
                //console.log(3333);

            }).catch(()=>{
                //console.log('unauthorized')
            });
        }
    }

    /*useEffect(() => {
        console.log(2222);
        UpdateMessages();
    },[]);*/

    return (
        <MessagesContent.Provider value={{ messagesArray, UpdateMessages}}>
            {children}
        </MessagesContent.Provider>
    );
};

export const useMessagesContext = () => {
    return useContext(MessagesContent);
};
