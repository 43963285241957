import React from 'react';
import ReactDOM from 'react-dom';
import { hydrate, render } from "react-dom";
import reportWebVitals from './reportWebVitals';
import './components/i18n.jsx';
import App from './App';

import { RouterProvider } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';

import router from './router.jsx';

/*
ReactDOM.render(<App />, document.getElementById('root'));
const rootElement = document.getElementById("root");*/
/*
if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(<App />, rootElement);
} else {
    ReactDOM.render(<App />, rootElement);
}*/
/*"postbuild": "react-snap"*/

ReactDOM.render(

    <App/>,
    document.getElementById('root')
);

/*
ReactDOM.createRoot(document.getElementById('root')).render(
    //<React.StrictMode>
        <AuthProvider>

            <RouterProvider router={router} />

        </AuthProvider>
   // </React.StrictMode>
);*/




/*
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <React.Suspense fallback="Loading...">
    <App />
      </React.Suspense>
  </React.StrictMode>
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/*reportWebVitals();*/
