import React, {useEffect, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import ReactDOM from "react-dom";
import button_close from "../../i/close.png";
import "../../styles/business/Modal.css"


const Modal = props => {

    const closeOnEscapeKeyDown = (e) => {
        if ((e.charCode || e.keyCode) === 27 ) {
            props.onClose()
        }
    }

    useEffect(() => {
        document.body.addEventListener('keydown', closeOnEscapeKeyDown)
        return function cleanup() {
            document.body.removeEventListener('keydown', closeOnEscapeKeyDown)
        }
    }, [])


    return ReactDOM.createPortal (

        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{enter: 0, exit: 300}}
            >

        <div className={'modal '+props.className} >
            <div className='modal-content-container' onClick={e => e.stopPropagation()}>
                <div className="modal-header-container">
                    <div className={'modal-header'}><h4 className="modal-title">{props.title}</h4></div>
                    <div className={'modal-close'} >
                        <img onClick={props.onClose} src={button_close} alt="button close" height={'20'}/>
                    </div>
                </div>
                <div className={"modal-body "+props.className}>

                    {props.children}

                </div>
            </div>
        </div>
        </CSSTransition>,

        document.getElementById('root')
    )
}

export default Modal
